var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("FormTransfor", { attrs: { value: _vm.row, disabled: _vm.disabled } }),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.preview },
            },
            [_vm._v("预览线路")]
          ),
          !_vm.disabled
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-check",
                  },
                  on: { click: _vm.submit },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small", icon: "el-icon-close" },
              on: { click: _vm.closeModal },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
      _c("MapPreview", {
        attrs: {
          visible: _vm.visible,
          list: _vm.row.sfaVisitclientRelRespVos || [],
        },
        on: {
          "update:visible": function ($event) {
            _vm.visible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }