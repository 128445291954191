<template>
  <div>
    <el-form>
      <div class="form-title">基本信息</div>
      <el-row :gutter="24">
        <el-col :md="12">
          <el-form-item class="flex" label="人员姓名" required>
            <el-select
              :disabled="disabled"
              v-model="value.fullName"
              filterable
              remote
              reserve-keyword
              placeholder="请输入人员姓名模糊搜索"
              :remote-method="remoteMethod"
              @change="getChange"
              :loading="loading">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="(item.orgName || '') + '/' + (item.positionName || '') + '/' + (item.fullName || '')"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item class="flex" label="人员职位">
            <el-input disabled v-model="value.posName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="12">
          <el-form-item class="flex" label="所属组织">
            <el-input disabled v-model="value.orgName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item class="flex" label="线路组名称" required>
            <el-input v-model="value.groupName" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="form-title">客户信息</div>
      <el-row :gutter="24" v-if="!disabled">
        <el-col :md="16" class="mb20">
          <el-button type="primary" @click="getDialog('customer')">添加经销商</el-button>
          <el-button type="primary" @click="getDialog('deport')">添加终端</el-button>
          <el-button type="primary" @click="delTable">删除</el-button>
        </el-col>
      </el-row>
    </el-form>
    <vxe-table :align="allAlign"
                v-if="value.sfaVisitclientRelRespVos && value.sfaVisitclientRelRespVos.length && isShow"
                ref="xTable"
                @checkbox-all="({records}) => selectAllEvent(records)"
                @checkbox-change="({records}) => selectChangeEvent(records)"
                :data="value.sfaVisitclientRelRespVos">
      <vxe-table-column type="checkbox"
                        width="50"></vxe-table-column>
      <vxe-table-column title="序号" width="80">
        <template v-slot="{ rowIndex}">
          <span>{{rowIndex+1}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column field="clientCode" title="客户编码" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column field="clientName" title="客户名称" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column field="clientTypeName" title="客户类型" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column field="clientSubclassName" title="客户细类" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column field="clientContacts" title="联系人" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column field="clientAddress" title="客户地址" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column title="排序" width="160">
        <template v-slot="{ rowIndex, row}">
          <el-button type="text" @click="moveRow('up', rowIndex, row)" v-if="rowIndex>0">上移一行</el-button>
          <el-button type="text" @click="moveRow('down', rowIndex, row)" v-if="rowIndex<value.sfaVisitclientRelRespVos.length-1">下移一行</el-button>
        </template>
      </vxe-table-column>
    </vxe-table>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect"/>
  </div>
</template>
<script>
import SelectConfig from '../../../../../../../components/select_org_cus_ter/select_config.vue';
import request from '../../../../../../../utils/request';
import * as utils from '../../../../../../../utils/index';

export default {
  components: {
    // Modal,
    SelectConfig,
  },
  props: {
    value: Object,
    disabled: Boolean,
  },
  data() {
    return {
      modalConfig: {
        title: '组织',
        visible: false,
        type: 'Modal',
      },
      checkList: [],
      selectionList: [],
      allAlign: null,
      options: [],
      loading: false,
      isShow: true,
      clickType: '',
    };
  },
  created() {
    this.getClientType();
    this.getNames();
  },
  methods: {
    // 数据字典获取客户类型
    getClientType() {
      request.post('/mdm/mdmdictdata/list', { dictTypeCode: 'sfacusType' }).then((res) => {
        console.log(res);
        if (res.success && res.result) {
          const clientType = {};
          res.result.map((v) => {
            clientType[v.dictCode] = v.dictValue;
            return v;
          });
          this.clientType = clientType;
        }
      });
    },
    // 更新数据
    emitChange() {
      this.$emit('input', this.value);
    },
    // 移动行数
    moveRow(type, index, row) {
      const arr = this.value.sfaVisitclientRelRespVos;
      if (type === 'up') {
        const temp = arr[index - 1];
        this.$set(arr, index - 1, arr[index]);
        this.$set(arr, index, temp);
      } else if (type === 'down') {
        const temp = arr[index + 1];
        this.$set(arr, index + 1, arr[index]);
        this.$set(arr, index, temp);
      }
      this.$refs.xTable.reloadData(this.value.sfaVisitclientRelRespVos);
      this.$forceUpdate();
      this.emitChange();
    },
    getDialog(type) {
      if (this.value.posCode) {
        const arr = JSON.parse(JSON.stringify(this.value.sfaVisitclientRelRespVos));
        this.clickType = type;
        arr.map((v) => {
          const item = v;
          item.id = item.clientId;
          return item;
        });
        const params = {
          functionCode: 'line_group_management_add_list2',
          data: arr,
          idKey: 'id',
          selectionList: arr,
          noReset: true,
          paramData: { positionCode: this.value.posCode },
        };
        if (type === 'deport') {
          params.functionCode = 'line_group_management_add_list1';
        }
        this.$refs.selectConfig.openSelectModal(params);
      } else {
        this.$message.error('请先选择人员姓名');
      }
    },
    onGetSelect(val) {
      if (!val || val.length <= 0) return false;
      const result = val;
      const newArr = [];
      if (this.clickType === 'customer') {
        val.map((item) => {
          newArr.push({
            clientCode: item.customerCode,
            clientName: item.customerName,
            clientType: 'dealer',
            clientTypeName: this.clientType.dealer,
            clientSubclass: item.customerType,
            clientSubclassName: item.customerTypeName,
            clientAddress: item.registeredAddress,
            clientContacts: item.contactName,
            clientId: item.id,
            clientPhoto: item.shopImagePath,
            clientPhone: item.contactPhone,
            latitude: item.latitude,
            longitude: item.longitude,
          });
          return item;
        });
      } else {
        val.map((item) => {
          // let clientType = '';
          // if (item.terminalType === '1') {
          //   clientType = 'terminal';
          // } else if (item.terminalType === '2') {
          //   clientType = 'batchDealer';
          // }
          newArr.push({
            clientCode: item.terminalCode,
            clientName: item.terminalName,
            clientType: 'terminal',
            clientTypeName: this.clientType.terminal,
            clientSubclass: item.terminalType,
            clientSubclassName: item.terminalTypeName,
            clientAddress: item.terminalAddress,
            clientContacts: item.contactName,
            clientId: item.id,
            clientPhoto: item.shopImagePath,
            clientPhone: item.contactPhone,
            latitude: item.latitude,
            longitude: item.longitude,
          });
          return item;
        });
      }
      this.$set(this.value, 'sfaVisitclientRelRespVos', utils.uniqueArr(this.value.sfaVisitclientRelRespVos, newArr, 'clientId'));
      this.$forceUpdate();
      this.emitChange();
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      list1.forEach((v1) => {
        let notRepeat = true;
        const rowData = v1;
        for (const v2 in list2) {
          if (v1[key] === list2[v2][key]) {
            notRepeat = true;
            return false;
          }
        }
        if (notRepeat) {
          list.push(rowData);
        }
        return rowData;
      });
      return list;
    },
    delTable() {
      if (this.selectionList.length) {
        this.selectionList.map((v) => {
          const item = v;
          const index = this.value.sfaVisitclientRelRespVos.findIndex((val) => val.clientId === item.clientId);
          if (index > -1) {
            this.value.sfaVisitclientRelRespVos.splice(index, 1);
            this.isShow = false;
            setTimeout(() => {
              this.isShow = true;
            }, 1);
          }
          return this.value;
        });
        this.selectionList = [];
      } else {
        this.$message.error('请选择数据');
      }
      this.$forceUpdate();
      this.emitChange();
    },
    selectChangeEvent(records) {
      this.selectionList = records;
    },
    selectAllEvent(records) {
      this.selectionList = records;
    },
    // 人员姓名远程搜索
    remoteMethod(query) {
      this.loading = true;
      this.getNames(query);
    },
    // 获取人员姓名数据
    getNames(unionName) {
      const datas = {
        enableStatus: '009',
        // allUnderThisPositionCode: JSON.parse(localStorage.getItem('userInfo')).positionCode,
        includeEmptyUserPosition: false,
        unionName,
      };
      request.post('/mdm/mdmPositionController/positionSelectList', datas).then((res) => {
        if (res.success) {
          this.options = res.result || [];
          this.loading = false;
        }
      });
    },
    // 人员姓名选中后
    getChange(e) {
      const index = this.options.findIndex((val) => val.id === e);
      if (index > -1) {
        this.value.posName = this.options[index].positionName;
        this.value.posCode = this.options[index].positionCode;
        this.value.orgName = this.options[index].orgName;
        this.value.fullName = this.options[index].fullName;
        this.value.orgCode = this.options[index].orgCode;
        this.value.sfaVisitclientRelRespVos = [];
        this.value.userName = this.options[index].userName;
        this.emitChange();
      }
    },
  },
};
</script>
<style lang="less" scoped>
  .flex{
    display: flex;
  }
  /deep/ .el-form-item__label{
    width: 125px;
  }
  /deep/ .el-form-item__content{
    .flex;
    align-items: center;
  }
  .mb20{
    margin-bottom: 20px;
  }
  .form-title{
    font-size: 16px;
    height: 20px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }
</style>
