var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        [
          _c("div", { staticClass: "form-title" }, [_vm._v("基本信息")]),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex",
                      attrs: { label: "人员姓名", required: "" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            placeholder: "请输入人员姓名模糊搜索",
                            "remote-method": _vm.remoteMethod,
                            loading: _vm.loading,
                          },
                          on: { change: _vm.getChange },
                          model: {
                            value: _vm.value.fullName,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "fullName", $$v)
                            },
                            expression: "value.fullName",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label:
                                (item.orgName || "") +
                                "/" +
                                (item.positionName || "") +
                                "/" +
                                (item.fullName || ""),
                              value: item.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "人员职位" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.value.posName,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "posName", $$v)
                          },
                          expression: "value.posName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "所属组织" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.value.orgName,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "orgName", $$v)
                          },
                          expression: "value.orgName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex",
                      attrs: { label: "线路组名称", required: "" },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.value.groupName,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "groupName", $$v)
                          },
                          expression: "value.groupName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "form-title" }, [_vm._v("客户信息")]),
          !_vm.disabled
            ? _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "mb20", attrs: { md: 16 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.getDialog("customer")
                            },
                          },
                        },
                        [_vm._v("添加经销商")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.getDialog("deport")
                            },
                          },
                        },
                        [_vm._v("添加终端")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.delTable },
                        },
                        [_vm._v("删除")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.value.sfaVisitclientRelRespVos &&
      _vm.value.sfaVisitclientRelRespVos.length &&
      _vm.isShow
        ? _c(
            "vxe-table",
            {
              ref: "xTable",
              attrs: {
                align: _vm.allAlign,
                data: _vm.value.sfaVisitclientRelRespVos,
              },
              on: {
                "checkbox-all": function (ref) {
                  var records = ref.records

                  return _vm.selectAllEvent(records)
                },
                "checkbox-change": function (ref) {
                  var records = ref.records

                  return _vm.selectChangeEvent(records)
                },
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { type: "checkbox", width: "50" },
              }),
              _c("vxe-table-column", {
                attrs: { title: "序号", width: "80" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var rowIndex = ref.rowIndex
                        return [_c("span", [_vm._v(_vm._s(rowIndex + 1))])]
                      },
                    },
                  ],
                  null,
                  false,
                  3930948016
                ),
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "clientCode",
                  title: "客户编码",
                  "min-width": "100",
                  "show-overflow": "",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "clientName",
                  title: "客户名称",
                  "min-width": "100",
                  "show-overflow": "",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "clientTypeName",
                  title: "客户类型",
                  "min-width": "100",
                  "show-overflow": "",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "clientSubclassName",
                  title: "客户细类",
                  "min-width": "100",
                  "show-overflow": "",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "clientContacts",
                  title: "联系人",
                  "min-width": "100",
                  "show-overflow": "",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "clientAddress",
                  title: "客户地址",
                  "min-width": "100",
                  "show-overflow": "",
                },
              }),
              _c("vxe-table-column", {
                attrs: { title: "排序", width: "160" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var rowIndex = ref.rowIndex
                        var row = ref.row
                        return [
                          rowIndex > 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.moveRow("up", rowIndex, row)
                                    },
                                  },
                                },
                                [_vm._v("上移一行")]
                              )
                            : _vm._e(),
                          rowIndex <
                          _vm.value.sfaVisitclientRelRespVos.length - 1
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.moveRow("down", rowIndex, row)
                                    },
                                  },
                                },
                                [_vm._v("下移一行")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1234716220
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }