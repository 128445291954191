<template>
  <el-dialog :title="modalConfig.title" :visible.sync="modalConfig.visible" fullscreen append-to-body @close="btnClose">
    <div ref="map" class="map"></div>
    <div class="dialog-footer">
      <el-button type="primary" @click="btnClose" size="small">确定</el-button>
      <el-button type="danger"  @click="btnClose" size="small" plain>取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
// import Modal from '../../../../../../../components/modal';

const appKey = process.env.VUE_APP_TENCENTMAP_KEY;

export default {
  components: {
    // Modal,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    visible: {
      deep: true,
      immediate: true,
      handler(val) {
        this.modalConfig.visible = val;
        if (val) {
          this.pointList = [];
          this.list.map((v, k) => {
            if (v.latitude && v.longitude) {
              this.pointList.push({ ...v, index: k });
            }
            return v;
          });
          setTimeout(() => {
            this.initMap();
            if (this.pointList.length <= 0) {
              this.initCityLocation();
            } else {
              this.setMakers(this.pointList);
              this.drowLine(this.pointList);
            }
          }, 500);
        }
      },
    },
  },
  data() {
    return {
      modalConfig: {
        title: '预览线路组',
        visible: false,
        type: 'Modal',
        width: '100%',
      },
      TMap: null,
      map: null,
      pointList: [],
    };
  },
  async mounted() {
    await this.syncLoadTMap();
  },
  beforeDestroy() {
    this.TMap = null;
    this.map = null;
  },
  methods: {
    /** @desc 按钮关闭 */
    btnClose() {
      this.$emit('update:visible', false);
    },
    /** @desc  异步加载腾讯地图 */
    syncLoadTMap() {
      return new Promise((resolve, reject) => {
        if (window.qq && window.qq.maps) {
          this.TMap = window.qq.maps;
          resolve(window.qq.maps);
        } else {
          const scriptMap = document.createElement('script');
          scriptMap.type = 'text/javascript';
          scriptMap.async = true;
          scriptMap.src = `https://map.qq.com/api/js?v=2.exp&key=${appKey}&callback=initTencentMap`;
          scriptMap.onerror = reject;
          scriptMap.dataTitle = 'tencentMap';
          document.body.appendChild(scriptMap);

          window.initTencentMap = () => {
            this.TMap = window.qq.maps;
            resolve(window.qq.maps);
          };
        }
      });
    },
    /** @desc 初始化地图 */
    initMap() {
      if (!this.TMap) return;
      this.map = new this.TMap.Map(this.$refs.map, {
        zoom: 16, // 设置地图缩放级别
        scrollwheel: false, // 启用滚动
      });
    },

    /** @desc 初始化城市位置查询 */
    initCityLocation() {
      if (!this.TMap) return;
      const that = this;
      this.cityLocation = new this.TMap.CityService({
        complete(result) {
          that.setCenter(result.detail.latLng.lat, result.detail.latLng.lng);
        },
      });
      this.cityLocation.searchLocalCity();
    },
    /** @desc 设置中心点 */
    setCenter(lat, lng) {
      if (!this.TMap) return;
      if (!this.map) return;
      const latLng = new this.TMap.LatLng(lat, lng);
      this.map.setCenter(latLng);
    },
    /** @desc 设置标记点 */
    setMakers(list) {
      if (!this.TMap) return;
      const that = this;
      if (!list || list.length <= 0) return;
      this.setCenter(list[0].latitude, list[0].longitude);
      const info = new this.TMap.InfoWindow({ map: this.map }); // 添加提示窗
      list.map((v) => {
        const item = v;
        const content = `<span style='color:#FFFFFF;'>${item.index + 1}</span>`;
        const decoration = new this.TMap.MarkerDecoration(content, new this.TMap.Point(0, -5));
        const marker = new this.TMap.Marker({ position: new this.TMap.LatLng(item.latitude, item.longitude), decoration, map: this.map }); // 创建标记
        //* **将必要的数据存入每一个对应的marker对象
        marker.clientName = item.clientName;
        marker.clientAddress = item.clientAddress;
        this.TMap.event.addListener(marker, 'click', (res) => { // 获取标记的点击事件
          const data = res.target;
          info.open(); // 点击标记打开提示窗
          info.setContent(`<div class="mapInfo" style="line-height: 20px; max-width: 300px;"><div>${data.clientName}</div><div>${data.clientAddress}</div></div>`); //* **设置提示窗内容（这里用到了marker对象中保存的数据）
          info.setPosition(new that.TMap.LatLng(data.position.lat, data.position.lng)); // 提示窗位置
        });
        return item;
      });
    },
    /** @desc 设置标记点 */
    drowLine(list, color = '#409EFF') {
      if (!this.TMap) return;
      if (!list || list.length <= 0) return;
      const polyArray = list.map((p) => new this.TMap.LatLng(p.latitude, p.longitude));
      const polyline = new this.TMap.Polyline({
        map: this.map,
        // 折线的路径
        path: polyArray,
        // 折线的颜色
        strokeColor: color,
        // 折线的宽度
        strokeWeight: 6,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.map{
  width: 100%;
  height: calc(100vh - 177px);
}
</style>
