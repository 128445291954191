var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.modalConfig.title,
        visible: _vm.modalConfig.visible,
        fullscreen: "",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.modalConfig, "visible", $event)
        },
        close: _vm.btnClose,
      },
    },
    [
      _c("div", { ref: "map", staticClass: "map" }),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.btnClose },
            },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small", plain: "" },
              on: { click: _vm.btnClose },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }