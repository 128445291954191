<template>
  <div>
    <FormTransfor :value="row" :disabled="disabled"></FormTransfor>
    <div class="dialog-footer">
      <el-button type="primary" @click="preview" size="small">预览线路</el-button>
      <el-button type="primary" @click="submit" size="small" icon="el-icon-check" v-if="!disabled">提交</el-button>
      <el-button type="danger"  @click="closeModal" size="small" icon="el-icon-close" >关闭</el-button>
    </div>
    <MapPreview  :visible.sync="visible" :list="row.sfaVisitclientRelRespVos||[]"></MapPreview>
  </div>
</template>
<script>
import request from '../../../../../../utils/request';
import FormTransfor from './components';
import MapPreview from './components/map_preview.vue';

export default {
  components: {
    FormTransfor,
    MapPreview,
  },
  data() {
    return {
      rule: [],
      row: {},
      disabled: false,
      visible: false,
    };
  },
  async created() {
    const { formConfig } = this.$attrs;
    if (formConfig.type === 'view') {
      this.disabled = true;
    }
    if (formConfig.type === 'edit' || formConfig.type === 'view') {
      request
        .get('/sfa/sfaVisitGroupController/queryDetailById', {
          id: formConfig.id,
        })
        .then((res) => {
          if (res.success) {
            const { result } = res;
            result.sfaVisitclientRelRespVos = result.sfaVisitclientRelRespVos.map((v) => ({ ...v, longitude: v.ext1, latitude: v.ext2 }));
            this.row = result;
          }
        });
    }
  },
  methods: {
    // 预览线路
    preview() {
      const list = this.row.sfaVisitclientRelRespVos;
      if (!list || list.length <= 0) {
        this.$message.warning('请先选择客户！');
        return false;
      }
      this.visible = true;
    },
    // 关闭
    closeModal() {
      this.$emit('onClose');
    },
    // 提交
    submit(val) {
      const { formConfig } = this.$attrs;
      const formData = this.row;
      const params = {
        fullName: formData.fullName,
        groupName: formData.groupName,
        orgName: formData.orgName,
        orgCode: formData.orgCode,
        posCode: formData.posCode,
        posName: formData.posName,
        userName: formData.userName,
        id: formData.id,
        sfaVisitclientRelReqVos: formData.sfaVisitclientRelRespVos.map((v) => ({ ...v, ext1: v.longitude, ext2: v.latitude })),
      };
      if (formData) {
        let url = '/sfa/sfaVisitGroupController/save';
        if (formConfig.type === 'edit') {
          url = '/sfa/sfaVisitGroupController/update';
        }
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });

            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
